<template>
    <div>
        <el-card>
            <nav class="out">
                <label
                    style="
                        font-size: 14px;
                        float: left;
                        transform: translateY(9px);
                    "
                    >发布日期：</label
                >
                <nav class="input">
                    <el-date-picker
                        v-model="querifo.publicdate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        size="medium"
                    />
                </nav>
                <nav class="input">
                    <el-input
                        v-model="querifo.title"
                        size="medium"
                        style="width: 80%; margin-left: 50px"
                        placeholder="输入标题"
                    />
                </nav>
                <nav class="input">
                    <el-select
                        v-model="querifo.checkStatus"
                        placeholder="审核状态"
                        style="width: 40.5%; margin-right: 30px"
                        size="medium"
                    >
                        <el-option label="全部" :value="-1" />
                        <el-option label="未通过" :value="0" />
                        <el-option label="通过" :value="1" />
                    </el-select>
                    <el-select
                        v-model="querifo.publishStatus"
                        placeholder="发布状态"
                        style="width: 40.5%"
                        size="medium"
                    >
                        <el-option label="全部" :value="-1" />
                        <el-option label="未发布" :value="0" />
                        <el-option label="发布" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select
                        v-model="querifo.classification"
                        placeholder="全部分类"
                        style="width: 100%"
                        size="medium"
                    >
                        <el-option label="全部" :value="-1" />

                        <el-option
                            v-for="item in fen"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select
                        v-model="querifo.bigPicture"
                        placeholder="展示方式"
                        style="width: 100%"
                        size="medium"
                    >
                        <el-option label="全部" :value="-1" />
                        <el-option label="小图展示" :value="0" />
                        <el-option label="大图展示" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-input
                        v-model="querifo.authName"
                        size="medium"
                        style="width: 80%; margin-left: 20px"
                        placeholder="编辑人员"
                    />
                </nav>
                <nav class="input">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="submit">{{
                        btn ? '搜索' : 'loading..'
                    }}</el-button>
                </nav>
            </nav>

            <!-- <el-button type="primary" class="btnAdd">新增资讯</el-button> -->
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
                @sort-change="sortChange"
            >
                <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                <el-table-column
                    prop="id"
                    width="80"
                    label="编号"
                    sortable="custom"
                />
                <el-table-column prop="title" label="标题" width="180">
                    <template slot-scope="ew">
                        <p>
                            <img
                                v-show="ew.row.top == 1"
                                src="../../assets/top.png"
                                alt=""
                                class="img-p"
                            />
                            <a
                                :href="`https:/${yu}/article/${ew.row.id}.html`"
                                target="“_blank”"
                                style="text-decoration: underline"
                                >{{ ew.row.title }}</a
                            >
                        </p>
                        <a
                            v-if="ew.row.originLink"
                            :href="ew.row.originLink"
                            target="“_blank”"
                            style="font-size: 12px"
                            >(原文链接)</a
                        >
                        <p v-if="ew.row.preview">
                            <a :href="ew.row.preview" target="“_blank”">
                                (PC预览)
                            </a>
                        </p>
                        <p v-if="ew.row.previewSunCode">
                            <a
                                :href="baseUrl + yu + ew.row.previewSunCode"
                                target="“_blank”"
                                >(小程序预览)</a
                            >
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="author" label="作者" />
                <el-table-column prop="authName" label="编辑人员" />
                <el-table-column prop="categoryName" label="分类" width="180" />

                <!-- <el-table-column prop="views" label="真实浏览量" sortable="custom">
        </el-table-column> -->
                <el-table-column prop="clickViews" label="浏览量">
                </el-table-column>

                <el-table-column
                    prop="orderNum"
                    label="排序"
                    width="120"
                    sortable="custom"
                >
                    <template #header>
                        <span>排序</span>
                        <br /><span style="font-size: 12px"
                            >（当前最大值：{{ orderMax }}）</span
                        >
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content">数值越大，显示越靠前</div>
                            <i
                                class="el-icon-warning-outline"
                                style="margin-left: 5px"
                            />
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <div class="input-box">
                            <el-input
                                v-if="pai[scope.$index].pai"
                                v-model="scope.row.orderNum"
                                size="small"
                                type="tel"
                                class="id"
                                @blur="
                                    handleInputBlur(
                                        scope.$index,
                                        scope.row.id,
                                        scope.row.orderNum
                                    )
                                "
                            />
                        </div>
                        <span
                            v-if="!pai[scope.$index].pai"
                            :class="
                                $store.state.btn_if.indexOf(
                                    'article_list_order'
                                ) != -1 && 'el-icon-edit'
                            "
                            @click="
                                $store.state.btn_if.indexOf(
                                    'article_list_order'
                                ) != -1 && showinput(scope.$index)
                            "
                        >
                            {{ scope.row.orderNum }}</span
                        >
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="top" label="状态" width="100">
          <template slot-scope="{ row }">
            <el-tag type="warning" size="small" v-show="row.top == 1">{{
              row.top == 1 ? '置顶' : ''
            }}</el-tag>
          </template>
        </el-table-column> -->
                <el-table-column
                    prop="checkStatus"
                    label="审核状态"
                    width="100"
                >
                    <template slot-scope="ew">
                        <el-tag v-if="ew.row.checkStatus == 1">通过</el-tag>
                        <el-tag v-else>未通过</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="publishStatus"
                    label="发布状态"
                    width="100"
                >
                    <template slot-scope="ew">
                        <el-tag v-if="ew.row.publishStatus == 1">发布</el-tag>
                        <el-tag v-else>未发布</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="发布时间"
                    sortable
                    width="180"
                />
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button
                            v-show="
                                $store.state.btn_if.indexOf(
                                    'article_list_edit'
                                ) != -1
                            "
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            class="skyblue"
                            @click="showEdit(scope.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            v-show="
                                $store.state.btn_if.indexOf(
                                    'article_list_delete'
                                ) != -1
                            "
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            class="red"
                            @click="removeuserid(scope.row.id)"
                            >删除</el-button
                        >

                        <el-button
                            v-if="scope.row.checkStatus == 0"
                            v-show="
                                $store.state.btn_if.indexOf(
                                    'article_list_check'
                                ) != -1
                            "
                            type="text"
                            icon="el-icon-s-check"
                            size="mini"
                            class="yellow"
                            @click="
                                setRole(scope.row.id, scope.row.checkStatus)
                            "
                            >审核通过</el-button
                        >
                        <el-button
                            v-else
                            v-show="
                                $store.state.btn_if.indexOf(
                                    'article_list_check'
                                ) != -1
                            "
                            type="text"
                            icon="el-icon-s-check"
                            size="mini"
                            class="yellow"
                            @click="
                                setRole(scope.row.id, scope.row.checkStatus)
                            "
                            >审核不通过</el-button
                        >
                        <el-button
                            v-if="scope.row.publishStatus == 0"
                            v-show="
                                $store.state.btn_if.indexOf(
                                    'article_list_publish'
                                ) != -1
                            "
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            class="green"
                            @click="
                                setRoles(scope.row.id, scope.row.publishStatus)
                            "
                            >发布</el-button
                        >
                        <el-button
                            v-else
                            v-show="
                                $store.state.btn_if.indexOf(
                                    'article_list_publish'
                                ) != -1
                            "
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            class="green"
                            @click="
                                setRoles(scope.row.id, scope.row.publishStatus)
                            "
                            >取消发布</el-button
                        >
                        <el-button
                            v-show="
                                $store.state.btn_if.indexOf('article_top') != -1
                            "
                            type="text"
                            icon="el-icon-top"
                            size="mini"
                            style="color: tan"
                            @click="
                                setTop(scope.row, scope.row.top == 0 ? 1 : 0)
                            "
                            >{{
                                scope.row.top == 0 ? '置顶' : '取消置顶'
                            }}</el-button
                        >
                        <el-button
                            v-show="
                                $store.state.btn_if.indexOf(
                                    'article_big_picture'
                                ) != -1
                            "
                            type="text"
                            icon="el-icon-camera-solid"
                            size="mini"
                            style="color: Salmon"
                            @click="
                                setBigPicture(
                                    scope.row,
                                    scope.row.bigPicture == 0 ? 1 : 0
                                )
                            "
                            >{{
                                scope.row.bigPicture == 0
                                    ? '大图展示'
                                    : '取消大图展示'
                            }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="querifo.page"
                background
                :page-sizes="[1, 2, 5, 10]"
                :page-size="querifo.pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <el-dialog
            title="修改文章"
            :visible.sync="editdialog"
            width="50%"
            @close="editclose"
        >
            <el-form
                ref="editFormRef"
                label-width="70px"
                :model="editform"
                :rules="addFormRulue"
            >
                <el-form-item label="标题" prop="realname">
                    <el-input v-model="editform.title" />
                </el-form-item>
                <el-form-item label="姓名" prop="username">
                    <el-input v-model="editform.realName" />
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="editform.email" />
                </el-form-item>
                <el-form-item label="手机" prop="mobile">
                    <el-input v-model="editform.phone" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editdialog = false">取 消</el-button>
                <el-button type="primary" @click="edituserinfo"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    data() {
        return {
            btn: true,
            value: '',
            value2: '',
            querifo: {
                query: '',
                page: 1,
                pageSize: 10,
                publicdate: [],
                title: '',
                checkStatus: '',
                publishStatus: '',
                classification: '',
                bigPicture: '',
                authName: '',
            },
            pai: [],
            paiform: {},
            str: '',
            title: '',
            authName: '',

            fen: [],
            editform: {
                title: '',
                categoryName: '',
                email: '',
                phone: '',
            },
            editdialog: false,
            addFormRulue: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                    {
                        min: 3,
                        max: 10,
                        message: '用户的长度在3~10个字符直接',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    {
                        min: 6,
                        max: 15,
                        message: '用户的长度在6~15个字符直接',
                        trigger: 'blur',
                    },
                ],
                realname: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                ],
            },
            form_2: {
                page: 1,
                pageSize: 10,
                publicDate: '',
                checkStatus: '',
                publishStatus: '',
                classification: '',
                order: '',
            },
            list: [],
            total: 0,
            order: '',
            yu: process.env.VUE_APP_STATIC_DIR,
            baseUrl: this.$store.state.env.cos,
            orderMax: 0,
        }
    },
    created() {
        this.getlist()
        this.getfen()
        sessionStorage.setItem('action', 'article')
    },
    methods: {
        setBigPicture(row, val) {
            this.$http
                .post('/admin/Article/bigPicture', {
                    id: row.id,
                    bigPicture: val,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message)
                        this.getlist()
                    } else {
                        this.$message({
                            message: res.message,
                            type: 'error',
                            customClass: 'zZindex',
                        })
                    }
                })
        },
        setTop(row, val) {
            // if (this.btn1) {
            this.$http
                .post('/admin/Article/top', {
                    id: row.id,
                    top: val,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message)
                        this.getlist()
                    } else {
                        this.$message({
                            message: res.message,
                            type: 'error',
                            customClass: 'zZindex',
                        })
                    }
                    // this.btn1 = true
                })
            // }
            // this.btn1 = false
        },
        sortChange(c) {
            console.log(c)
            if (c.order) {
                var s = 'article_create_' + c.prop
                if (c.prop == 'orderNum') {
                    s = 'article_order_num'
                } else if (c.prop == 'views') {
                    s = 'views'
                } else {
                    s = 'article_' + c.prop
                }
                var p = c.order.slice(0, c.order.length - 6)
                this.order = s + ' ' + p
            } else {
                this.order = ''
            }
            console.log(this.order)
            this.getlist()
        },
        handleInputBlur(id, ids, orderNum) {
            this.paiform.id = ids
            this.paiform.orderNum = orderNum
            // console.log(this.paiform)
            this.$http
                .post('/admin/Article/sort', this.paiform)
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message)
                    }
                })
            this.pai[id].pai = false
        },
        showinput(id) {
            console.log(id)
            this.pai[id].pai = true
            setTimeout(() => {
                $('.id input').focus()
            }, 20)
        },
        editclose() {
            this.$refs.editFormRef.resetFields()
        },
        rowClass() {
            return 'background:#F5F7FA;'
        },

        async showEdit(id) {
            // console.log(id);
            // const { data: res } = await this.$http.get(
            //   '/admin/Article/getById?id=' + id
            // )
            // if (res.errorCode != 200) {
            //   return this.$message.error('查询用户失败')
            // }
            // this.editform = res.data
            // this.editdialog = true
            sessionStorage.setItem('flag', false)
            this.$router.push({
                path: '/article_add',
                query: {
                    id: id,
                },
            })
        },
        edituserinfo() {
            this.$refs.editFormRef.validate(async (val) => {
                if (!val) return
                const { data: res } = await this.$http.post(
                    '/admin/Article/edi',
                    this.editform
                )
                if (res.meta.status == 200) {
                    this.$message.success('更新成功')
                    this.editdialog = false
                    this.getlist()
                }
            })
        },
        handleSizeChange(e) {
            // console.log(e)
            this.querifo.pageSize = e
            this.getlist()
        },
        handleCurrentChange(e) {
            // console.log(e)
            this.querifo.page = e

            // this.getlist()
            this.$http
                .get('/admin/Article/getList', {
                    params: {
                        ...this.form_2,
                        page: this.querifo.page,
                    },
                })
                .then(({ data: res }) => {
                    this.list = res.data.list
                    this.total = res.data.totalCount
                    this.btn = true
                    // console.log(this.list);
                })
        },
        getlist() {
            this.$http
                .get('/admin/Article/getList', {
                    params: {
                        ...this.querifo,
                        order: this.order,
                    },
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.list = res.data.list
                        this.total = res.data.totalCount
                        this.orderMax = res.data.orderMax
                        console.log(res.data)
                        this.list.forEach((item) => {
                            this.pai.push({ id: item.id, pai: false })
                        })
                    }
                    // console.log(this.list);
                })
        },
        getfen() {
            this.$http
                .get('/admin/ArticleCategory/getList')
                .then(({ data: res }) => {
                    // console.log(res);
                    if (res.errorCode == 200) {
                        this.fen = res.data.list
                    }
                })
        },
        setRole(id, el) {
            if (el == 1) {
                el = 0
            } else {
                el = 1
            }
            this.$http
                .post('/admin/Article/check', { id: id, checkStatus: el })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message)
                        this.getlist()
                    }
                })
        },
        setRoles(id, el) {
            if (el == 1) {
                el = 0
            } else {
                el = 1
            }
            this.$confirm('请确认无误后点确定！ 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .post('/admin/Article/publish', {
                            id: id,
                            publishStatus: el,
                        })
                        .then(({ data: res }) => {
                            if (res.errorCode == 200) {
                                this.$message.success(res.message)
                                this.getlist()
                            }
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    })
                })
        },
        removeuserid(id) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$http
                        .delete('/admin/Article/del', { params: { id: id } })
                        .then(({ data: res }) => {
                            if (res.errorCode == 200) {
                                this.$message.success(res.message)
                                if (this.total % 10 == 1) {
                                    this.querifo.page--
                                }
                                this.getlist()
                            }
                        })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        submit() {
            if (this.btn) {
                this.querifo.page = 1
                this.form_2 = {
                    ...this.querifo,
                    publicDate:
                        this.querifo && this.querifo.publicdate.join('~'),
                }
                this.$http
                    .get('/admin/Article/getList', {
                        params: {
                            ...this.form_2,
                        },
                    })
                    .then(({ data: res }) => {
                        this.list = res.data.list
                        this.total = res.data.totalCount
                        this.btn = true
                        // console.log(this.list);
                    })
            }
            this.btn = false
        },
        reset() {
            this.querifo = {
                query: '',
                page: 1,
                pageSize: 10,
                publicdate: [],
                title: '',
                checkStatus: '',
                publishStatus: '',
                classification: '',
                bigPicture: '',
                authName: '',
            }
            // this.getlist()
            this.submit()
        },
    },
}
</script>

<style lang="less">
.el-input--medium .el-input__inner {
    height: 36px;
    line-height: 36px;
}
.out {
    // width: 500px;
    // width: 1360px;
    width: 100%;

    margin-bottom: 10px;
    // display: flex;
    justify-content: space-around;

    .input {
        float: left;
        vertical-align: middle;
        width: 300px;
        margin-right: 12px;
        margin-bottom: 12px;
        // display: inline-block;
        // margin-right: 30px;
    }
}
.red {
    color: red;
}
.el-table th > .cell {
    text-align: center;
}

.el-table .cell {
    text-align: center;
}
.skyblue {
    color: skyblue;
}
.green {
    color: green;
}
.yellow {
    color: pink;
}
.bottom {
    margin-top: 15px;
}
</style>
